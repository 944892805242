import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { AiFillMail, AiFillLinkedin, AiFillInstagram} from 'react-icons/ai'
import { MdSailing } from 'react-icons/md'
import {socialSection, footer} from '../components/layout.module.css'
// import CVDownload from '../files/Yang_Fei_CV_NEW.pdf'

const AboutPage = () => {
  return (
    <Layout pageTitle="Me">
    <div data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="500"
            data-sal-easing="ease">
      <StaticImage width={100} height={100} src="https://i.ibb.co/dGYTCGM/Screenshot-2023-11-16-at-3-20-35-am.png" alt="avator"/>
      {/* <p>"They love fashion, I live fashion, I am fashion" - Yang Fei</p>
      <p>Hi, I'm a dyke, lesbian, female to female, understand?</p>
      <p>Biggest achievement so far: Survived</p>
      <p>Currently a (not really) happy dev in 📍Shanghai</p>
      <p>23.1.23 started - 2.2 biuble - 2.22 THE BIG TRUTH 2.28(ideally) bitter</p>
      <p>I'm thinking, real lesbian community?</p> */}
      {/* <p><a className={downloadLink} download href={CVDownload}>Download my CV.</a></p> */}
      <div className={socialSection}>
        {/* <a 
          target="_blank" 
          rel="noreferrer"
          href={"mailto:yangfei0777@gmail.com"} 
          aria-label="email"><AiFillMail /></a> */}
        {/* <a 
          target="_blank" 
          rel="noreferrer"
          href={"https://opensea.io/enzozzz321"} 
          aria-label="opensea"><MdSailing /></a>
        <a 
          target="_blank" 
          rel="noreferrer"
          href={"https://www.linkedin.com/in/yangfei123/"} 
          aria-label="linkedin"><AiFillLinkedin /></a>
        <a 
          target="_blank" 
          rel="noreferrer"
          href={"https://www.instagram.com/yanggg_fei/"} 
          aria-label="instagram"><AiFillInstagram /></a> */}
        
      </div>
      </div>
    </Layout>
  )
}

export default AboutPage